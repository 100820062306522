<template>

    <div :class="(props.type_content == 2 && props.type == 3 ? '' : 'mg_content')">

      <div class="divider">
        {{ props.is_close ? (!props.list.items_user || props.list.items_user.length ? $t('giveaways_view_winners') : '') : $t('giveaways_view_growers') }}
      </div>
      
      <div v-if="props.type == 2 || props.type == 3 || props.type == 4 || (props.type == 6 && props.is_close)" class="growers_boxs results">
        <GeneralGrower 
          v-for="(opt,ind) in props.list.items_user"
          :key="ind"  
          :data="opt"      
          >       
        </GeneralGrower>
      </div>
      
      <div v-else-if="(props.type == 6 && !props.is_close)" class="mg_list">
        <GiveawayParticipantSolution
          v-for="(opt,ind) in props.list.items_user"
          :key="ind"  
          :data="opt"
          :position="ind + 1"
          :max_votes="Math.max.apply(Math, props.list.items_user.map(function(o) { return o.cnt_vote; }))"
          >       
        </GiveawayParticipantSolution>
      </div>


    </div>

</template>

<script setup>

const props = defineProps({
  list: {
    type: Object
  },
  type: {
    type: Number
  },
  type_content: {
    type: Number
  },
  enable_vote: {
    type: Number
  },
  is_vote: {
    type: Number
  },
  is_start: {
    type: Number
  },
  is_close: {
    type: Number
  },
  game_id: {
    type: Number
  }
})

onMounted(() => {
  if(props.list.items_user && props.list.items_user.length){
    if(props.type == 3 || props.type == 4 || (props.type == 6 && props.is_close)){
      props.list.items_user = props.list.items_user.sort((a, b) => a.place > b.place ? 1 : -1);
    }else if(props.type == 6 && !props.is_close){
      props.list.items_user = props.list.items_user.sort((a, b) => a.cnt_vote < b.cnt_vote ? 1 : -1);
    }
  }
})


// export default {   
//   components: {
    
//   },
//   props: {
//     list: {
//       type: Object
//     },
//     type: {
//       type: Number
//     },
//     type_content: {
//       type: Number
//     },
//     enable_vote: {
//       type: Number
//     },
//     is_vote: {
//       type: Number
//     },
//     is_start: {
//       type: Number
//     },
//     is_close: {
//       type: Number
//     },
//     game_id: {
//       type: Number
//     }
//   },
//   data () {
//     return {            
//     }
//   },
//   created() {    
//     // this.query = this.$route.query.q;
//     if(this.list.items_user && this.list.items_user.length){
//       if(this.type == 3 || this.type == 4 || (this.type == 6 && this.is_close)){
//         this.list.items_user = this.list.items_user.sort((a, b) => a.place > b.place ? 1 : -1);
//       }else if(this.type == 6 && !this.is_close){
//         this.list.items_user = this.list.items_user.sort((a, b) => a.cnt_vote < b.cnt_vote ? 1 : -1);
//       }
//     }
//   },
//   methods: {
     
//   }
// }
</script>

<style scoped>
 
.grower_item {
        width: 100%;
    display: block;
    position: relative;
    border-radius: 3px;
    margin-top: 7px;
    color: #212121;
    text-align: left;
    border-bottom: 1px #d4d4d5 solid;
    padding-bottom: 7px;
}

.grower_item:hover {
    color: #212121;
}

.grower_item .about_row {
    width: 100%;
    display: flex;
    position: relative;
    min-height: 65px;
    justify-content: space-between;
}
.grower_item .stat_grid{
    display: none;
}


.grower_item .position{
    display: inline-block;
    width: 54px;
    text-align: center;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.grower_item .position .num sup{

}
.grower_item .position .num{
    border-radius: 30px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #bbbbbb;
    display: inline-block;
    height: 24px;
}

.grower_item  .position .icon{
    font-size: 0.75rem;
    margin-top: -3px;
    color: var(--un-primary-back-color);
    display: inline-block;
}
.grower_item  .position .icon.up{
    color: var(--un-primary-back-color);
}

.grower_item  .position .icon.down{
    color: red;
}

.grower_item  .position .icon span {
    font-weight: bold;
    font-size: 0.75rem;
    vertical-align: bottom;
    margin-left: 3px;
}


.grower_item .about_row .photo {
        display: inline-block;
        width: 80px;
        vertical-align: top;
        position: relative;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
}

.grower_item .about_row .photo img {
   border-radius: 150px;
    min-height: 50px;
    width: 50px;
}

.grower_item .about_row .names {
    display: inline-block;
    width: calc(40% - 110px);
    vertical-align: top;
    position: relative;
    padding-top: 10px;
}

.grower_item .about_row .photo .user_range {
       font-size: 1.6rem;
    /* margin-top: 6px; */
    margin-right: 15px;
    position: absolute;
    right: 0px;
    top: 37px;
}

.grower_item .about_row .names .status .btn_chat{
    display: inline-block;
    color: gray;
    font-size: 0.85rem;
    padding: 5px;
    border-radius: 3px;
    margin-left: 5px;
}
.grower_item .about_row .names .status .btn_chat.online{

    color: var(--un-primary-back-color);
}
.grower_item .about_row .names .status .btn_chat:hover{
    color: white;
    background-color: var(--un-primary-back-color);
}

.grower_item .about_row .names .name {
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grower_item .about_row .names .status {
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.grower_item .about_row .names .status .icon.circle.online{
    color: var(--un-primary-back-color);
}
.grower_item .about_row .names .status .icon.circle{
    color: gray;
    font-size: 0.75rem;
}
.grower_item .about_row .names .status .icon.trophy{
    color: #cccccc;
    display: none;
}
.grower_item .about_row .names .status .icon.gold{
    color: gold;
}
.grower_item .about_row .stat {
    display: flex;
        justify-content: flex-end;
    width: 60%;
    vertical-align: top;
    position: relative;
    padding-top: 10px;
    padding-right: 10px;
}

.grower_item .about_row .stat .item {
    display: inline-block;
    text-align: center;

    min-width: 90px;
}
.grower_item .about_row .stat .item.is_empty {
        opacity: 0.4;
}
.grower_item .is-awards{
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('/images/trophy/diary_two.png');
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-bottom: -5px;
    margin-left: -7px;
    margin-right: -7px;
}
.grower_item .is-awards.gold{
    background-image: url('/images/trophy/diary_first.png');
}
.grower_item .is-awards-user{
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('/images/trophy/grower_first.svg');
    width: 25px;
    height: 26px;
    display: inline-block;
    margin-bottom: -5px;
    margin-left: -7px;
    margin-right: -7px;
}
.grower_item .item_awards .value{
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: -11px;
}
.grower_item .item_awards_3 .is-awards:nth-child(2){
    position: absolute;
    margin-left: -42px;
    height: 25px;
    width: 25px;
    margin-top: 4px;
    z-index: -1;
}
.grower_item .item_awards_3 .is-awards:nth-child(3){
    position: absolute;
    margin-left: -22px;
    height: 25px;
    width: 25px;
    margin-top: 4px;
    z-index: -1;
}


.grower_item .item_awards_2 .is-awards:nth-child(1){

}
.grower_item .item_awards_2 .is-awards:nth-child(2){
margin-left: -24px;
    position: absolute;
    z-index: -1;
}

.grower_item .about_row .stat .item .value {
    font-size: 1.2rem;
    color: #4e4e4e;
}
 .grower_item .stat .item .gw,
 .grower_item .stat .item .gp{

    width: 18px;
    height: 18px;
    vertical-align: top;
 }

.grower_item .about_row .stat .item .title {
    color: gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grower_item .about_row .stat .item.likes .value i{
     color: var(--un-primary-back-color);
}

.grower_item .favorite_row {
    padding-left: 68px;
    white-space: nowrap;
    overflow-x: hidden;
    opacity: 0.3;
    margin-right: 20px;
}
.grower_item:hover .favorite_row {
    opacity: 1;
}
.grower_item .favorite_row .favs{
    text-align: right;
}
.grower_item .favorite_row .social{
        display: inline-block;
    width: 60px;
    margin-right: 10px;
    text-align: center;
    float: left;
}

.grower_item .favorite_row .social .social_instagram{
    height: 1rem;
    width: 1rem;
    vertical-align: middle;
}
.grower_item .favorite_row .social .icon.youtube{
    font-size: 1.1rem;
    color: #e52d27;
}

.grower_item .favorite_row .social .icon.facebook{
    font-size: 1.1rem;
    color: #3b5999;
}

.grower_item .favorite_row .favs .title {
    display: inline-block;
    font-weight: bold;
}

.grower_item .favorite_row .fav_item {
    display: inline-block;
    margin-left: 15px;
}

.grower_item .favorite_row .fav_item .hint {
    color: gray;
    display: inline-block;
}

.grower_item .favorite_row .fav_item .img {
    display: inline-block;
    width: 19px;
    vertical-align: middle;
    margin: 0px 10px;
}
.grower_item .favorite_row .fav_item .img img{
    display: inline-block;
    width: 100%;
    margin-top: 3px;
    margin-bottom: -3px;
}

.grower_item .favorite_row .fav_item .name {
    display: inline-block;
}


/* RESPONSE */


@media (min-width: 1100px) {
  .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .grower_item{
      width: 48%;
  }
  .grower_item .about_row .stat .item_gp,
  .grower_item .about_row .stat .item_gw{
      display: none;
  }
  .grower_item .about_row .stat{
    width: 180px;
  }
  .grower_item .about_row .names{
    width: calc(100% - 260px);
  }
}

@media (min-width: 1350px) {
  .grower_item .about_row .stat .item_gp,
  .grower_item .about_row .stat .item_gw{
      display: inline-block;
  }
}

/* WITH SIDEBAR */


@media (min-width: 1100px) {
  .show_sidebar .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .show_sidebar .grower_item{
      width: 48%;
  }
  .show_sidebar .grower_item .about_row .stat .item_gp,
  .show_sidebar .grower_item .about_row .stat .item_gw{
      display: none;
  }
  .show_sidebar .grower_item .about_row .stat{
    width: 180px;
  }
  .show_sidebar .grower_item .about_row .names{
    width: calc(100% - 260px);
  }
}

@media (min-width: 1550px) {
  .show_sidebar .grower_item .about_row .stat .item_gp,
  .show_sidebar .grower_item .about_row .stat .item_gw{
      display: inline-block;
  }
}



@media (max-width: 768px) {
  .growers_boxs{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
   .grower_item .about_row .stat .item_gp,
   .grower_item .about_row .stat .item_gw{
      display: none;
  }
   .grower_item .about_row .stat{
    width: 180px;
  }
   .grower_item .about_row .names{
    width: calc(100% - 260px);
  }
}

@media (max-width: 500px) {

   .grower_item .about_row .stat .likes{
      display: none;
  }
   .grower_item .about_row .names{
    width: calc(100% - 180px);
  }
}


</style>
