<template>


    <h2>{{ $t('giveaways_view_pick_diary') }}</h2>

    <br>

    <UiMessage
      type="orange"
      icon="icon-warning-round"
      >
      {{ $t('giveaways_view_dont_have_diaries_to_join_hint') }}  
    </UiMessage>

    <div :class="{'list': true, 'gloading': is_loading}">
      <div v-if="!giveaways_data?.items_diary?.length">
        {{ $t('giveaways_view_dont_have_diaries_to_join') }}
      </div>
      <div 
        v-else 
        class="diary" 
        v-for="(d) in giveaways_data.items_diary"
        >
        <img class="ava" :src="d.avatar_small">
        <div class="name">
          {{ d.name }}
        </div>
        <div class="is-part">
          {{ d.is_participant ? 'Used' : ''}}
        </div>
        <div class="join">
          <UiButton
            :type="'primary'"
            :disabled="d.is_participant"
            @click="pick(d.id)"
            >
            {{ $t('giveaways_view_join') }}
          </UiButton>
        </div>
      </div>
    </div>
 

</template>

<script setup>

const { $api } = useNuxtApp();

const props = defineProps({
  data: {
    type: Object
  },
  category: {
    type: Object
  }
})


const emits = defineEmits(['pick']);

const pick = (id) => {
  emits('pick', id);
}

const loadData = async function() {   

  return await $api.getGiveawayUserChooseDiary(props.data.id, props.category.id)  
}


const { pending: is_loading,  data: giveaways_data } = await useLazyAsyncData('giveaways_data', loadData)

 

</script>

<style scoped>


.list{
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;

  height: 200px;
}
 .diary{
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #eee;
 }

.ava{
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-right: 10px;
}
.name{
  margin-right: 1rem;
}
.is-part{
  margin-left: auto;
}
.join{
  margin-left: 1rem;
}

</style>
