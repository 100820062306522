<template>

    <div :class="'diary ' + prewinner_class">
      <div class="num">
         <span v-if="is_close">{{ data.place ? data.place : '' }}</span>
         <span v-else>{{ num }}</span>
      </div>
      <NuxtLink class="cover" :to="'/diaries/' + data.link">
        <img :src="data.avatar_little">
      </NuxtLink>
      <NuxtLink class="name_diary" :to="'/diaries/' + data.link">
        <span>{{ data.name }}</span>
      </NuxtLink>
      <div class="user">
        <div class="avatar"><img class="" :alt="data.item_user.name" :src="data.item_user.avatar_small"></div>
        <div :class="'user_range u_range_' + data.item_user.status"></div>
        <div class="info">
          <div class="name">{{ data.item_user.name }}</div>
          <div class="status">{{ $constants.status[data.item_user.status] ? $t($constants.status[data.item_user.status]) : '' }}</div>
        </div>
      </div>
      <div :class="'weight ' + (data.avg_weight_watt >= 0.01 ? 'active' : '')">
        <div class="sum_weight">{{ (data.avg_weight_watt >= 0.01) ? data.avg_weight_watt.toFixed(2) : '-' }}</div>
        <div class="hint_weight">{{ $t('universal_unit_gram') }}/{{ $t('universal_unit_watt') }}</div>
      </div>

      <div v-if="enable_vote && false" :class="'likes ' + (data.is_voted ? 'active' : '')">
        <div v-if="is_close" :class="'ui btn ' + (is_vote && !data.is_voted ? '' : 'disable')" @click="$giveaways.vote(game_id,category_id,data.contest_id)">
          <i class="icon thumbs up"></i>
          <span class="title_like">{{ $t('giveaways_view_vote') }}</span>
          <div v-if="data.cnt_vote && show_position" class="ui btn_cnt" @click="$giveaways.showVotes(game_id,data.contest_id)">
            <span class="count">{{ data.cnt_vote ? data.cnt_vote : '' }}</span>
          </div>
        </div>
        <div v-else-if="data.cnt_vote">
          <span class="show_votes" @click="$giveaways.showVotes(game_id,data.contest_id)">{{ data.cnt_vote }} <i class="icon thumbs up"></i> {{ $t('giveaways_view_vote') }}</span>
        </div>
        <div v-else>
          <div class="ui btn disable"><i class="icon thumbs up"></i> <span class="title_like">{{ $t('giveaways_view_no_vote') }}</span></div>
        </div>
      </div>
    </div>

</template>

<script>
 

export default {   
  props: {
    data: {
      type: Object
    },
    type_content: {
      type: Number
    },
    max_winners: {
      type: Number
    },
    enable_vote: {
      type: Number
    },
    is_vote: {
      type: Number
    },
    is_join: {
      type: Number
    },
    is_close: {
      type: Number
    },
    game_id: {
      type: Number
    },
    category_id: {
      type: Number
    },
    num: {
      type: Number
    }
  },
  data () {
    return {   
      prewinner: true,
      prewinner_count: 0,
      prewinner_class: '',
      show_position: true
    }
  },
  created() {
    this.prewinner_count = (this.data.type == 5) ? 3 : 10;
    this.prewinner = ((this.is_vote || this.is_join) || (this.max_winners && this.num > this.max_winners)) ? false : true;
    this.prewinner_class = (/*(this.num <= this.prewinner_count && this.prewinner && !this.is_close) ||*/ (this.data.place && this.data.place <= this.prewinner_count && this.is_close && this.prewinner)) ? 'prewinner' : '';
    this.show_position = (this.is_close || this.is_join) ? false : true;   
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
.awards_page{

    position:  relative;
}

/* TABS */

.tabs{
    height: 67px;
    text-align: left;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    display: flex;

}


.day_shadow_left .shadow_inner,
.day_shadow_right .shadow_inner{
    background-color: transparent;
    padding: 3px 0px 17px 10px;
    border-radius: 30px;
    height: 40px;
    width: 50px;
    cursor: pointer;
}

.day_shadow_left:hover .shadow_inner,
.day_shadow_right:hover .shadow_inner{
    background-color: white;
}
.day_shadow_left{
    position: absolute;
    left: 0;
    z-index: 10;
    top: 7px;
    display: none;
    font-size: 1.7em;
    padding: 9px 0px 12px 10px;
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 72%, rgba(255,255,255,0) 90%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 72%,rgba(255,255,255,0) 90%);
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 72%,rgba(255,255,255,0) 90%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#00e2e2e2',GradientType=1 );

}
.day_shadow_right{
    position: absolute;
    right: 0;
    z-index: 10;
    top: 7px;
    display: none;
    font-size: 1.7em;
    padding: 9px 0px 17px 10px;
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 90%);
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 90%);
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 90%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e2e2e2', endColorstr='#e2e2e2',GradientType=1 );

}


.tabs .tab .in_progress{
    font-size: 1.1rem;
    background-color: var(--un-primary-back-color);
    padding: 1px 4px 0px 4px;
    border-radius: 3px;
    margin-top: -9px;
    color: white;
    font-style: initial;
    display: inline-block;
}
.tabs .tab{
    display: inline-block;

    border-radius: 5px 5px 0px 0px;
    padding: 10px;
    cursor: pointer;
    vertical-align: middle;
    text-align: left;
    border-bottom: 2px white solid;
}
.all_months{
    padding: 14px;
}
.all_months .text{
    position: relative;
    font-size: 1.2rem;
}
.all_months  .dropdown.icon{
    position: relative;
}
.tabs .tab:hover{
    border-bottom: 2px #484848 solid;
}
.tabs .tab {
    color: var(--un-text-color);
}
.tabs .tab .logo{
    max-width: 45px;
    display: inline-block;
    vertical-align: top;
}
.tabs .tab .name{
    display: inline-block;
    margin-right: 10px;
    margin-left: 15px;
}
.tabs .tab .name .month{
    font-size: 1.2rem;
}
.tabs .tab .name .bank{
    color: gray;
}

.tabs .tab.active{
    cursor: default;
    border-bottom: 2px #484848 solid;
}
.ui.vertical.stripe .horizontal.divider{
    margin-top: 0px!important;
}
.tabs .tab.active .name .bank{
    /* color: white; */
}


.countdown{
    width: 100%;
    text-align: center;
}
.countdown .hint{
    display: inline-block;
    font-size: 1.5rem;

    margin-top: 38px;
    margin-right: 5px;
    margin-left: 10px;
}
.countdown .clock{
    display: inline-block;
    width: auto;
    margin-top: 21px;
    margin-bottom: -8px;
    vertical-align: top;
}
.countdown .vote_count{
    display: inline-block;
    font-size: 1.5rem;
    margin-top: 38px;
    margin-right: 10px;
}
.countdown .vote_count.vote_used .thank{
    display: inline-block;
}
.countdown .vote_count.vote_used .counter{
    display: none;
}
.countdown .vote_count .counter{
    display: inline-block;
}
.countdown .vote_count .thank{
    display: none;
}


/* DIARIES */

.diaries_wait{
    text-align: center;
    width: 100%;
}
.diaries{
    margin-top: 20px;
    width: 100%;
}
.diaries .col{
    display: inline-block;
    width: 49%;
    vertical-align: top;
}
.diaries .col:nth-child(2n){
    margin-left: 1%;
}

.diaries .col .diary{
    border: none;
    border-radius: 0.28571429rem;
    box-shadow: 0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5;
    display: table;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    color: var(--un-text-color);
}
.diaries .col .diary.prewinner{
        box-shadow: 0px 1px 3px 0px #e2b924, 0px 0px 0px 1px #edc662;
    background-color: #fffaef;
}
.diaries .col .diary .num{
    display: table-cell;
    vertical-align: middle;
    width: 50px;
    text-align: center;
    font-size: 1.2rem;
    color: gray;
}
.diaries .col .diary .cover{
    display: table-cell;
    vertical-align: middle;
    width: 60px;
    height: 60px;
}
.diaries .col .diary .cover > a{display: block;position: relative;height: 60px;}
.diaries .col .diary .cover img{
    width: 60px;
    height: 60px;
    display: block;
}
.diaries .col .diary .name_diary > a {
    display: inline-block;
    padding-left: 10px;
    max-height: 36px;
    overflow-y: hidden;
    max-width: 120px;
    display: inline-block;
    color: var(--un-text-color);
}
.diaries .col .diary  .rating_position{
       display: none;
    /* position: absolute; */
    text-align: center;
    /* padding: 1px 6px; */
    color: white;
    font-size: 0.75rem;
    /* right: -4px; */
    font-weight: bold;
    bottom: 4px;
    /* left: 0px; */
    /* width: 45px; */
    vertical-align: text-top;
    margin-top: 8px;
    margin-left: -3px;
    margin-right: -5px;
}
.diaries .col .diary  .rating_position.plus{
    display: inline-block;
    color: var(--un-primary-back-color);
    border-radius: 20px;
}
.diaries .col .diary  .rating_position.minus{
    display: inline-block;
    color: #f52626;
    border-radius: 20px;
}
.diaries .col .diary  .rating_position .icon{
    margin-right: -1px;
}
.diaries .col .diary .name_diary span{
         white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    vertical-align: middle;
    position: absolute;
    width: 90%;
    margin-top: -9px;
}
.diaries .col .diary .name_diary{
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
    color: var(--un-text-color);
    position: relative;
}
.diaries .col .diary .user{
    display: table-cell;
    vertical-align: middle;
    width: 130px;
}
.diaries .col .diary .weight{
    display: table-cell;
    vertical-align: middle;
    width: 60px;
    opacity: 0.4;
    text-align: center;
}
 .diaries .col .diary .weight.active{
     opacity: 1;
 }
.diaries .col .diary .likes{
    display: table-cell;
    vertical-align: middle;
    width: 160px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 110px;
    text-align: right;
}
.diaries.is_waiting .col .diary .likes{
    display: none;
}
.diaries .col .diary .likes.is_stop{

}
.diaries .col .diary .likes.active .btn .title_like{
    display: none;
}
.diaries .col .diary .likes .count{

}
.diaries .col .diary .likes .btn.disable{
    pointer-events: none!important;
    opacity: 0.5;
}
.diaries .col .diary .user .avatar {
    display: inline-block;
    width: 38px;
    vertical-align: baseline;
}

.diaries .col .diary .user .avatar img {
    border-radius: 50%;
    width: 37px;
}

.diaries .col .diary .user .info {
    display: inline-block;
    width: calc(100% - 47px);
    margin-left: 5px;
}
.diaries .col .diary .user .user_range {
    float: left;
    font-size: 1.6rem;
    margin-right: 15px;
    position: absolute;
    margin-left: -28px;
    bottom: 10px;
}

.diaries .col .diary .user .info .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90px;
    display: inline-block;
}

.diaries .col .diary .user .info .status {
    color: #9c9c9c;
    font-size: 0.85rem;
    margin-top: -2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/**/

.winners{
    margin-top: 20px;
}

.rules_btn{
    display: block;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}


/* COMMENTS */

.comments_content_game{
    margin-top: 40px;
}
.comments_content_game > .divider{

}
.comments_content_game .comments_game{
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}


/* LIST VOTES */

#uimodal_votes .title{
  font-size: 1.7rem;
}
#uimodal_votes .data > .content{
  padding: 0px;
  margin: 30px;
}
.votes {
  display: flex;
  flex-wrap: wrap;
}
.votes .user {
  position: relative;
  max-width: 170px;
  text-align: left;
  width: 50%;
  margin-bottom: 5px;
}
.votes .user .avatar {
    display: inline-block;
    width: 38px;
    vertical-align: sup;
}

.votes .user .avatar img {
    border-radius: 50%;
    width: 37px;
}
.votes .user .status {
    color: #b1b1b1;
}
.votes .user .info {
    display: inline-block;
    width: calc(100% - 47px);
    margin-left: 5px;
}
.votes .user .user_range {
    float: left;
    font-size: 1.6rem;
    margin-right: 15px;
    position: absolute;
    margin-left: -28px;
    bottom: 4px;
}



/* GROWERS MONTH */




.growers_info{
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 560px);
}
.growers_info .growers_rule{
     margin-top: 20px;
     width: 100%;
}
.growers_info .growers_rule_small{
     margin-top: 20px;
     width: 100%;
     font-size: 0.85rem;
    color: #b70400;
}

.growers_info .title{

}
.growers_info .mobile_title{
    display: none;
}






@container pb (max-width: 768px) {

  .diaries .col{
      width: 100%!important;
      margin: 0px!important;
  }

  .diaries .col .weight{
      display: none;
  }

  .diaries .col{
      width: 100%;
      margin: 0px!important;
  }

  .diaries .col .weight{
      display: none;
  }
  .diaries .col .user{
      display: none;
  }
}



</style>
