<template>
  <div v-if="timeLeft" class="timer">
    <div class="el">
      <div class="num">{{ timeLeft.days }}</div> 
      <div class="hint">Days</div>
    </div>
    <div class="dots">:</div>
    <div class="el">
      <div class="num">{{ timeLeft.hours }}</div> 
      <div class="hint">Hours</div>
    </div>
    <div class="dots">:</div>
    <div class="el">
      <div class="num">{{ timeLeft.minutes }}</div> 
      <div class="hint">Minutes</div>
    </div>
    <div class="dots">:</div>
    <div class="el">
      <div class="num">{{ timeLeft.seconds }}</div> 
      <div class="hint">Seconds</div>
    </div>
  </div>
  <div v-else>
    <!-- Время истекло -->
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';

const props = defineProps({
  timeunix: {
    type: Number,
    required: true
  }
});

const endTime = ref(new Date(props.timeunix * 1000));
const timeLeft = ref(calculateTimeLeft(endTime.value));

const intervalId = ref(null);

onMounted(() => {
  intervalId.value = setInterval(() => {
    timeLeft.value = calculateTimeLeft(endTime.value);
  }, 1000);
});

onUnmounted(() => {
  clearInterval(intervalId.value);
});

watch(() => props.timeunix, (newVal) => {
  endTime.value = new Date(newVal * 1000);
  timeLeft.value = calculateTimeLeft(endTime.value);
});

function calculateTimeLeft(endTime) {
  const now = new Date();
  const difference = endTime - now;

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  } else {
    return null;
  }
}
</script>

<style scoped>

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.num {
  font-size: 30px;
  font-weight: bold;
}

.hint {
  font-size: 12px;
  font-weight: bold;
}

.dots {
  font-size: 30px;
  font-weight: bold;
  margin: 0 10px;
  color: gray;
}
</style>
