<template>

    <div class="list_diary">


      <div v-if="is_close" class="ui horizontal divider">
        {{ $t('giveaways_view_winners') }}
      </div>
      <div v-else class="ui horizontal divider">
        {{ $t('giveaways_view_diaries') }}
      </div>


      <div v-if="is_start">
 
        <GiveawayParticipantEmpty
          v-if="!list.items_diary?.length"
          />

        <div v-else class="diaries">
          <div class="col">

            <template v-for="(item, item_key) in list.items_diary.slice(0,Math.ceil(list.items_diary.length/2))">
              <GiveawayParticipantDiary 
                :data="item"
                :type_content="type_content"
                :max_winners="max_winners"
                :enable_vote="enable_vote"
                :is_close="is_close"
                :is_vote="is_vote"
                :game_id="game_id"
                :num="item_key+1"
                >
              </GiveawayParticipantDiary> 
            </template>

          </div>

          <div class="col">

            <template v-for="(item, item_key) in list.items_diary.slice(Math.ceil(list.items_diary.length/2))">
              <GiveawayParticipantDiary 
                :data="item"
                :type_content="type_content"
                :max_winners="max_winners"
                :enable_vote="enable_vote"
                :is_close="is_close"
                :is_vote="is_vote"
                :game_id="game_id"
                :num="Math.ceil(list.items_diary.length/2)+ item_key +1"
                >
              </GiveawayParticipantDiary> 
            </template>

          </div>
        </div>

      </div>

      <div v-else class="coming_soon_giveaway">
        {{ $t('giveaways_view_no_participants') }}
      </div>

    </div>

</template>

<script>



export default {   
  
  props: {
    list: {
      type: Object
    },
    type_content: {
      type: Number
    },
    max_winners: {
      type: Number
    },
    enable_vote: {
      type: Number
    },
    is_vote: {
      type: Number
    },
    is_join: {
      type: Number
    },
    is_start: {
      type: Number
    },
    is_close: {
      type: Number
    },
    game_id: {
      type: Number
    },
    category_id: {
      type: Number
    }
  },
  data () {
    return {  
    }
  },
  created() {    
    // this.query = this.$route.query.q;
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
/* DIARIES */

.diaries_wait{
    text-align: center;
    width: 100%;
}
.diaries{
    margin-top: 20px;
    width: 100%;
}
.diaries .col{
    display: inline-block;
    width: 49%;
    vertical-align: top;
}
.diaries .col:nth-child(2n){
    margin-left: 1%;
}

.diaries .col .diary{
    border: none;
    border-radius: 0.28571429rem;
    box-shadow: 0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5;
    display: table;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
    color: var(--un-text-color);
}
.diaries .col .diary.prewinner{
        box-shadow: 0px 1px 3px 0px #e2b924, 0px 0px 0px 1px #edc662;
    background-color: #fffaef;
}
.diaries .col .diary .num{
    display: table-cell;
    vertical-align: middle;
    width: 50px;
    text-align: center;
    font-size: 1.2rem;
    color: gray;
}
.diaries .col .diary .cover{
    display: table-cell;
    vertical-align: middle;
    width: 60px;
    height: 60px;
}
.diaries .col .diary .cover > a{display: block;position: relative;height: 60px;}
.diaries .col .diary .cover img{
    width: 60px;
    height: 60px;
    display: block;
}
.diaries .col .diary .name_diary > a {
    display: inline-block;
    padding-left: 10px;
    max-height: 36px;
    overflow-y: hidden;
    max-width: 120px;
    display: inline-block;
    color: var(--un-text-color);
}
.diaries .col .diary  .rating_position{
       display: none;
    /* position: absolute; */
    text-align: center;
    /* padding: 1px 6px; */
    color: white;
    font-size: 0.75rem;
    /* right: -4px; */
    font-weight: bold;
    bottom: 4px;
    /* left: 0px; */
    /* width: 45px; */
    vertical-align: text-top;
    margin-top: 8px;
    margin-left: -3px;
    margin-right: -5px;
}
.diaries .col .diary  .rating_position.plus{
    display: inline-block;
    color: var(--un-primary-back-color);
    border-radius: 20px;
}
.diaries .col .diary  .rating_position.minus{
    display: inline-block;
    color: #f52626;
    border-radius: 20px;
}
.diaries .col .diary  .rating_position .icon{
    margin-right: -1px;
}
.diaries .col .diary .name_diary span{
         white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    vertical-align: middle;
    position: absolute;
    width: 90%;
    margin-top: -9px;
}
.diaries .col .diary .name_diary{
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
    color: var(--un-text-color);
    position: relative;
}
.diaries .col .diary .user{
    display: table-cell;
    vertical-align: middle;
    width: 130px;
}
.diaries .col .diary .weight{
    display: table-cell;
    vertical-align: middle;
    width: 60px;
    opacity: 0.4;
    text-align: center;
}
 .diaries .col .diary .weight.active{
     opacity: 1;
 }
.diaries .col .diary .likes{
    display: table-cell;
    vertical-align: middle;
    width: 160px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 110px;
    text-align: right;
}
.diaries.is_waiting .col .diary .likes{
    display: none;
}
.diaries .col .diary .likes.is_stop{

}
.diaries .col .diary .likes.active .btn .title_like{
    display: none;
}
.diaries .col .diary .likes .count{

}
.diaries .col .diary .likes .btn.disable{
    pointer-events: none!important;
    opacity: 0.5;
}
.diaries .col .diary .user .avatar {
    display: inline-block;
    width: 38px;
    vertical-align: baseline;
}

.diaries .col .diary .user .avatar img {
    border-radius: 50%;
    width: 37px;
}

.diaries .col .diary .user .info {
    display: inline-block;
    width: calc(100% - 47px);
    margin-left: 5px;
}
.diaries .col .diary .user .user_range {
    float: left;
    font-size: 1.6rem;
    margin-right: 15px;
    position: absolute;
    margin-left: -28px;
    bottom: 10px;
}

.diaries .col .diary .user .info .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90px;
    display: inline-block;
}

.diaries .col .diary .user .info .status {
    color: #9c9c9c;
    font-size: 0.85rem;
    margin-top: -2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}





@container pb (max-width: 768px) {

.diaries .col{
    width: 100%!important;
    margin: 0px!important;
}

.diaries .col .weight{
    display: none;
}

.diaries .col{
    width: 100%;
    margin: 0px!important;
}

.diaries .col .weight{
    display: none;
}
.diaries .col .user{
    display: none;
}
}


/**/

.winners{
    margin-top: 20px;
}

.rules_btn{
    display: block;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}


</style>
