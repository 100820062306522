<template>



    <h2 class="title" v-if="category.type_content == 5">{{ $t('giveaways_upload_photo') }}</h2>
    <h2 class="title" v-if="category.type_content == 6">{{ $t('giveaways_upload_video') }}</h2>

    <br>

    <UiMessage
      v-show="!edited_photo"
      type="orange"
      icon="icon-warning-round"
      >
      {{ $t('giveaways_view_dont_have_diaries_to_join_hint') }}  
    </UiMessage>

    <UiUploader
      v-show="!edited_photo"
      url="/uploads/giveaways/media"
      :enable-drag-drop="!edited_photo"
      :btn-wide="true"
      :is-multiple="false"
      :btn-name="(category.type_content == '5' ? $t('giveaways_view_add_photo') :  $t('giveaways_view_add_video'))"
      @changed="changePhotos" 
      @uploaded="uploadedPhotos" 
      @progress="uploadingPhotos" 
      />

    <div v-if="edited_photo" class="photo">


      <div v-if="edited_photo.is_loading" class="ava_cont">
        <img class="ava" :src="edited_photo.thumbnail">
        <div class="progress">{{ edited_photo.progress }}%</div>
      </div>
      <div v-else class="ava_cont">
        <img class="ava" :src="edited_photo.size_m">
      </div>
      
      <div class="acts">
 

        <UiButton
          :disabled="edited_photo.is_loading"
          @click="removePhoto"
          >
          Reupload file
        </UiButton>

        <UiButton
          :type="'primary'"
          :disabled="edited_photo.is_loading"
          @click="pick"
          >
          Continue join
        </UiButton>
      </div>
      
    </div>
 

</template>

<script setup>

const { $api } = useNuxtApp();

const props = defineProps({
  data: {
    type: Object
  },
  category: {
    type: Object
  }
})


const emits = defineEmits(['pick']);

const edited_photo = ref(null);

const pick = () => {
  console.log('edited_photo.value.id');
  console.log(edited_photo.value.id);
  emits('pick', edited_photo.value.id);
}
 
 

const changePhotos = function(uploading_id, thumbnail){
  edited_photo.value = {is_loading: true, uploading_id: uploading_id, thumbnail: thumbnail};
}

const uploadedPhotos = function(item, uploading_id){   
  console.log('uploaded');
  console.log(item);
  edited_photo.value = item; 
} 

const uploadingPhotos = function(progress, uploading_id){      
  edited_photo.value.progress = progress;
}

const removePhoto = function(key){            
  edited_photo.value = null;
}

</script>

<style scoped>
.title{
  text-align: center;
}

.list{
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;

  height: 200px;
}
 .diary{
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #eee;
 }
 
.join{
  margin-left: auto;
}
.photo{
  width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}
.photo .ava_cont{
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.photo .progress{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 40px;
  padding: 5px 20px;
}
.photo .ava{
  
  border-radius: 5px;
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 310px;
  object-fit: cover;

}

.acts{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
</style>
