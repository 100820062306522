<template>
 
    <div class="">
      <div class="flow_box_compact">
      <h2 class="ttl">{{ props.gw_title }}</h2>
      <div class="list" v-if="list && list.length">
        <NuxtLink class="item" :to="'/giveaways/' + data.link" v-for="(data, item_key) in list" :key="item_key" :style="'background-image: url(' + data.cover_back + ');'">
          <div class="inf">
            <div class="nm">{{ data.short_name }}</div>
          </div>
        </NuxtLink>
      </div>
      <div onclick="" class="show_all">{{ $t('universal_show_all') }}</div>
      </div>
    </div>
  
</template>

<script setup>

const props = defineProps({
  list: {
    type: Array
  },
  gw_title: {
    type: String
  }
})


 
</script>

<style scoped>
 

/* FLOW COMPACT LIST */
.flow_box_compact {
  padding: 2rem;
  background-color: white;
  padding-bottom: 1rem;
}

.flow_box_compact .ttl {
  margin: 0;
  font-size: 2rem !important;
  margin-bottom: 1.5rem;
}

.flow_box_compact .show_all {
  color: gray;
  text-transform: uppercase;
  cursor: pointer;
}

.flow_box_compact .hint {}

.flow_box_compact .list {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  flex-wrap: wrap;
  height: 290px;
  justify-content: center;
}

.flow_box_compact .list .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  height: 280px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  width: 180px;
  min-width: 180px;
  margin-right: 1rem;
  border-radius: 10px;
}

.flow_box_compact .list .item .cover {
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  margin-right: 15px;
  border-radius: 10px;
}

.flow_box_compact .list .item .inf {
  background-color: white;
  width: 90%;
  border-radius: 10px;
  left: 5%;
  position: absolute;
  padding: 7px 13px;
  bottom: 10px;
  box-shadow: 0px 0px 10px #00000047;
}

.flow_box_compact .list .item .inf .nm {
  font-weight: bold;
  line-height: 1.3rem;
}

@media (max-width: 768px) {


  .flow_box_compact .list{
    height: 220px;
  }

  .flow_box_compact .list .item {
    height: 210px;
  }

  .flow_box_compact {
    height: auto;
    padding: 1.5rem 1.5rem;
    padding-bottom: 0.5rem;
  }

}

@media (max-width: 400px) {


    .flow_box_compact .list .item {
      width: calc(50% - 1rem);
      min-width: calc(50% - 1rem);
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }


}


</style>
