<template>

    <div class="video_item">

      <div class="img_gallery" :data-gallery-id="data.id" data-type="video">
        <div class="video_play"></div>
        <div v-if="data.duration" class="media_duration">{{ data.duration }}</div>
        <img :src="data.size_m" alt="">
      </div>
      
      <div class="video_info">
        <NuxtLink class="title" :to="'/grower/' + data.name_user" v-if="type_content == 6">
          {{ data.name_user }}
        </NuxtLink>
        <NuxtLink class="title" :to="'/diaries/' + data.link_diary + '/week/' + data.week_id + '#gallery=' + data.id">
          {{ data.report_strain }} {{ $t('giveaways_view_by_user') }} {{ data.name_user }}
        </NuxtLink>
      </div>

      <div v-if="enable_vote && false">

        <div v-if="is_vote">
          <div :class="'ui btn ' + (is_vote && data.is_voted ? '' : 'disable')" @click="$giveaways.vote(game_id,category_id,data.contest_id)">
            <i class="icon thumbs up"></i>
            <span class="title_like">{{ $t('giveaways_view_vote') }}</span>
          </div>

          <div v-if="data.cnt_vote" class="btn_cnt" @click="$giveaways.howVotes(game_id,data.contest_id)">
            <i class="icon thumbs up"></i>
            <span class="count">{{ data.cnt_vote }}</span>
          </div>
        </div>

        <div v-else-if="data.cnt_vote">
          <span class="show_votes" 2click="$giveaways.showVotes(game_id,data.contest_id)">{{ data.cnt_vote }} <i class="icon thumbs up"></i> {{ $t('giveaways_view_vote') }}</span>
        </div>

        <div v-else>
          <div class="ui btn disable"><i class="icon thumbs up"></i> <span class="title_like">{{ $t('giveaways_view_no_vote') }}</span></div>
        </div>

      </div>

    </div>

</template>

<script>
 

export default {   
  props: {
    data: {
      type: Object
    },
    type_content: {
      type: Number
    },
    enable_vote: {
      type: Number
    },
    is_vote: {
      type: Number
    },
    game_id: {
      type: Number
    },
    category_id: {
      type: Number
    }
  },
  data () {
    return {            
    }
  },
  created() {    
    // this.query = this.$route.query.q;     
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
.contest_list{
   width: 100%;
}


.countdown{
    width: 100%;
    text-align: center;
}
.countdown .hint{
    display: inline-block;
    font-size: 1.5rem;

    margin-top: 38px;
    margin-right: 5px;
    margin-left: 10px;
}
.countdown .clock{
    display: inline-block;
    width: auto;
    margin-top: 21px;
    margin-bottom: -8px;
    vertical-align: top;
}
.countdown .vote_count{
    display: inline-block;
    font-size: 1.5rem;
    margin-top: 38px;
    margin-right: 10px;
}
.countdown .vote_count.vote_used .thank{
    display: inline-block;
}
.countdown .vote_count.vote_used .counter{
    display: none;
}
.countdown .vote_count .counter{
    display: inline-block;
}
.countdown .vote_count .thank{
    display: none;
}

/* PICK VIDEOS */


.add_content_box{
    display: block;
    text-align: center;
    margin-top: 20px;
}
.add_content_box .video_item{
    display: inline-block;
    width: 120px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    vertical-align: top;
    position: relative;
}
.add_content_box .video_item .video_info{

}

.add_content_box .video_item .video_info .title{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
}
.add_content_box .video_item .video_info .hint{
    color: gray;
    display: block;
}

.add_content_box .video_item > .img_gallery{
    display: inline-block;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}
.add_content_box > .video_item > .img_gallery > img{
    width: 100%;
    height: auto;
    border-radius: 3px;
    aspect-ratio: 1/1;
    object-fit: contain;
}
.add_content_box .video_play {
    width: 56px;
    height: 56px;
    z-index: 1;
    position: absolute;
    background-image: url(/images/play_button.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 62px;
    left: calc(50% - 28px);
    top: calc(50% - 31px);
    cursor: pointer;
    pointer-events: none;
}


/* PICK VIDEOS */


.add_content_box{
    display: block;
    text-align: center;
    margin-top: 20px;
}
.add_content_box .photo_item{
  display: flex;
      width: 100%;
      margin-bottom: 10px;
      position: relative;
      flex-direction: row;
      align-items: center;
}
.add_content_box .photo_item .btn_pick{
    width: 70px;
}
.add_content_box .photo_item .photo_info{
  margin-right: 20px;
      width: calc(100% - 180px);
      text-align: left;
}

.add_content_box .photo_item .photo_info .title{
    display: block;
}
.add_content_box .photo_item .photo_info .hint{
    color: gray;
    display: block;
}

.add_content_box .photo_item > .img_gallery{
  display: inline-block;
      width: 70px;
      height: auto;
      overflow-y: hidden;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      margin-right: 20px;
}
.add_content_box > .photo_item > .img_gallery > img{
    width: 100%;
    height: auto;
    border-radius: 3px;
}


/* DIARIES */




.contest_special{
    display: block;
    text-align: center;
    margin-top: 20px;
}
.contest_special .video_item{
        display: inline-block;
        width: 160px;
        margin-left: 5px;
        margin-right: 5px;
        vertical-align: top;
        position: relative;
        margin-bottom: 15px;
}
.contest_special .video_item .video_info{

}

.contest_special .video_item .video_info .title{
    display: block;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.contest_special .video_item .video_info .hint{
    color: gray;
    display: block;
}

.contest_special .video_item > .img_gallery{
    display: inline-block;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}
.contest_special > .video_item > .img_gallery > img{
    width: 100%;
    height: auto;
    border-radius: 3px;
}
.contest_special .video_play {
    width: 56px;
    height: 56px;
    z-index: 1;
    position: absolute;
    background-image: url(/images/play_button.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 62px;
    left: calc(50% - 28px);
    top: calc(50% - 31px);
    cursor: pointer;
    pointer-events: none;
}

.contest_special .media_duration{
  background: rgb(0, 0, 0);
    /* margin-bottom: 10px; */
    padding: 4px;
    /* max-width: 720px; */
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.53); */
    border-radius: 4px;
    width: 58px;
    text-align: left;
    margin-bottom: 0px;
    position: absolute;
    /* max-height: 100%; */
    overflow: hidden;
    line-height: 16px;
    z-index: 1;
    max-width: 70%;
    color: white;
    font-size: 0.85rem;
    opacity: 0.8;
    text-align: center;
    right: 6px;
    bottom: 13px;
}




/* DIARIES */




.contest_photo{
    display: block;
    text-align: center;
    margin-top: 20px;
}
.contest_photo .photo_item{
        display: inline-block;
        width: 160px;
        margin-left: 5px;
        margin-right: 5px;
        vertical-align: top;
        position: relative;
        margin-bottom: 15px;
}
.contest_photo .photo_item .photo_info{

}

.contest_photo .photo_item .photo_info .title{
    display: block;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.contest_photo .photo_item .photo_info .hint{
    color: gray;
    display: block;
}

.contest_photo .photo_item > .img_gallery{
    display: inline-block;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}
.contest_photo > .photo_item > .img_gallery > img{
    width: 100%;
    height: auto;
    border-radius: 3px;
}

/* MOBILE */



@media (max-width: 900px) {

  .gw_content .diaries_special .col{
      width: 100%;
      margin: 0px!important;
  }

  .gw_content .diaries_special .col .weight{
      display: none;
  }
  .gw_content .diaries_special .col .user{

      display: none;
  }

}




/* EXAMPLES VIDEO IN DESCRIPTION */


.examples_v_special{
    display: flex;
    text-align: center;
    margin-top: 20px;
    flex-wrap: wrap;
}
.examples_v_special .vi{
        display: inline-block;
        width: 120px;
        /* margin-left: 5px; */
        margin-right: 5px;
        vertical-align: top;
        position: relative;
        margin-bottom: 5px;
}
.examples_v_special .vi .video_info{

}

.examples_v_special .vi .video_info .title{
    display: block;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.examples_v_special .vi .video_info .hint{
    color: gray;
    display: block;
}

.examples_v_special .vi > .img_gallery{
    display: inline-block;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}
.examples_v_special > .vi > .img_gallery > img{
    width: 100%;
    height: auto;
    border-radius: 3px;
}
.examples_v_special .video_play {
    width: 56px;
    height: 56px;
    z-index: 1;
    position: absolute;
    background-image: url(/images/play_button.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 62px;
    left: calc(50% - 28px);
    top: calc(50% - 31px);
    cursor: pointer;
    pointer-events: none;
}

.examples_v_special .media_duration{
  background: rgb(0, 0, 0);
    /* margin-bottom: 10px; */
    padding: 4px;
    /* max-width: 720px; */
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.53); */
    border-radius: 4px;
    width: 58px;
    text-align: left;
    margin-bottom: 0px;
    position: absolute;
    /* max-height: 100%; */
    overflow: hidden;
    line-height: 16px;
    z-index: 1;
    max-width: 70%;
    color: white;
    font-size: 0.85rem;
    opacity: 0.8;
    text-align: center;
    right: 6px;
    bottom: 13px;
}

/**/


</style>
