<template>


    <!-- <div v-if="contents.items_photo_diary_list">{{ $t('giveaways_view_pick_photo') }}</div>
    <div v-else>{{ $t('giveaways_view_pick_diary') }}</div> -->

    <div class="pick_diary_content">


      <UiButtonDropdown
        name="Choose from my diaries"
        :ico="'icon-rocket'"
        :type="'wide center'"
        :options="pickDiaryActions"
        @choose="pickDiary" 
        />

        <br>



        <!-- {{ pick_photo_video_diary }} -->

      <GiveawayJoinPickPhotoVideoDiaryItem
        v-if="picked_diary_id"
        :diary-id="picked_diary_id"
        :diary-name="picked_diary_name"
        :data="props.data"
        :category="props.category"
        @pick="emits('pick', $event)"
        />
      <div v-else class="pick_diary">
        Pick diary

        <br>
        <br>

        <UiMessage
          type="orange"
          icon="icon-warning-round"
          >
          {{ $t('giveaways_view_dont_have_diaries_to_join_hint') }}  
        </UiMessage>
    
        
      </div>

    </div>

</template>

<script setup>

const { $api } = useNuxtApp();

const props = defineProps({
  data: {
    type: Object
  },
  category: {
    type: Object
  }
})


const emits = defineEmits(['pick']);

const picked_diary_id = ref(null);
const picked_diary_name = ref(null);

const pickDiaryActions = computed(() => {
  var items = [];

  pick_photo_video_diary.value?.items_photo_diary?.forEach((item, key) => {
    items.push({
      event: 'choose',
      id: item.id,
      name: item.name,
    });
  });

  pick_photo_video_diary.value?.items_video_diary?.forEach((item, key) => {
    items.push({
      event: 'choose',
      id: item.id,
      name: item.name,
    });
  });

  return items;
})

// const pick = (id) => {
//   emits('pick', id);
// }

const pickDiary = (option) => {
  picked_diary_id.value = option.id;
  picked_diary_name.value = option.name;
}

const loadData = async function() {   
  return await $api.getGiveawayUserChoosePhotoVideoDiary(props.data.id, props.category.id)  
}


const { pending: is_loading,  data: pick_photo_video_diary } = await useLazyAsyncData('pick_photo_video_diary', loadData)

 

</script>

<style scoped>

.pick_diary{
  padding: 20px;
  text-align: center;
}
.pick_diary_content{
  padding: 0 20px;
  height: 100%;
}
</style>
