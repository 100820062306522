<template>
 
 
 <!-- {{ pick_photo_video_diary_item.items_photo_diary_content }} -->

    <h2 class="ttl_diary">{{ diaryName }}</h2>

    <div 
      v-if="list_content?.length"
      :class="{'list_items': true, 'gloading': is_loading}"
      >
      <div class="list_items_in">
        <div 
          class="item"         
          v-for="(d) in list_content"
          @click="pick(d.video_id ? d.video_id : d.id)"
          >
          <img class="img" :src="d.size_m">
          <div class="week">Week {{ d.week }}</div> 
        </div>
      </div>
    </div>

    <div v-else class="empty">
      Empty
    </div>

</template>

<script setup>

const { $api } = useNuxtApp();

const props = defineProps({
  diaryId:{
    type: Number,
    required: true,
  },
  diaryName:{
    type: String,
    required: true,
  },
  data: {
    type: Object
  },
  category: {
    type: Object
  }
})


const emits = defineEmits(['pick']);

const pick = (id) => {
  if(confirm('Pick this item?')){
    emits('pick', id);
  }
}

const loadData = async function() {   
  return await $api.getGiveawayUserChoosePhotoVideoDiaryContent(props.data.id, props.category.id, props.diaryId)  
}

const list_content = computed(() => {
  var items = [];

 

  pick_photo_video_diary_item.value?.items_photo_diary_content?.forEach((item, key) => {
    items.push(item);
  }); 
  

  pick_photo_video_diary_item.value?.items_video_diary_content?.forEach((item, key) => {
    items.push(item);
  }); 

  return items;
})

const { pending: is_loading,  data: pick_photo_video_diary_item } = await useLazyAsyncData('pick_photo_video_diary_item', loadData)

 
watch(() => props.diaryId, async (newVal, oldVal) => {
  if(newVal != oldVal){
    is_loading.value = true;
    pick_photo_video_diary_item.value = await loadData();
    is_loading.value = false;
  }
})

</script>

<style scoped>


.list_items{
  /* overflow-y: auto; */
  /* height: 400px; */
  position: relative;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.list_items_in{
  display: flex;
  flex-wrap: wrap;  
  gap: 0.5rem;
  width: 100%;
}
.item{
  width: calc(100% / 4 - 0.5rem);
  position: relative;
  
}
.img{
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 5px;
}
.join{
  margin-left: auto;
}
.week{

  text-align: center;
  position: absolute;
  bottom: 4px;
  background-color: white;
  font-weight: bold;
  font-size: 0.7rem;
  padding: 2px 4px;
  border-radius: 0 3px 0 0;
}

.ttl_diary{
  text-align: center;
  width: 100%;
}

@media (max-width: 768px){
  .item{
    width: calc(100% / 3 - 0.5rem);
  }
  .list_items{
    height: calc(100%);
  }
}

</style>
