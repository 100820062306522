<template>

    <div class="contest_list">

      <div v-if="props.list.items_photo_winners?.length">

          <div class="divider">
            {{ $t('giveaways_view_winners') }}
          </div>


        <div class="contest_photo" id="diaries_award">

          <template v-for="(item, item_key) in props.list.items_photo_winners">
            <GiveawayParticipantPhoto 
              :data="item"
              :type_content="props.type_content"
              :enable_vote="props.enable_vote"
              :is_vote="props.is_vote"
              :game_id="props.game_id"
              @click="openWinnersGallery(item_key)"
              >
            </GiveawayParticipantPhoto> 
          </template>

        </div>

      </div>

      <div class="divider">
        {{ $t('giveaways_view_photos') }}
      </div>


      <div v-if="is_start"> 
        <GiveawayParticipantEmpty
          v-if="!list.items_photo?.length"
          />
        <div v-else class="coming_soon_giveaway">
          <div class="contest_photo" id="diaries_award">

            <template v-for="(item, item_key) in props.list.items_photo">
              <GiveawayParticipantPhoto 
                :data="item"
                :type_content="props.type_content"
                :enable_vote="props.enable_vote"
                :is_vote="props.is_vote"
                :game_id="props.game_id"
                :category_id="props.category_id"
                @click="openGallery(item_key)"
                >
              </GiveawayParticipantPhoto> 
            </template>

          </div>
        </div>
      </div>
      <div v-else class="coming_soon_giveaway">
        {{ $t('giveaways_view_coming_soon') }}
      </div>




    </div>

    <GeneralLightbox 
      v-if="lightboxWinnersShow"
      :index="lightboxWinnersIndex" 
      :photos="lightboxWinnersPhotos" 
      @close="lightboxWinnersShow = false" 
      />

    <GeneralLightbox 
      v-if="lightboxShow"
      :index="lightboxIndex" 
      :photos="lightboxPhotos" 
      @close="lightboxShow = false" 
      />


</template>

<script setup>

const props = defineProps({
  list: {
    type: Object,
    required: true,
  },
  type_content: {
    type: Number,
    required: true,
  },
  enable_vote: {
    type: Number,
    required: true,
  },
  is_vote: {
    type: Number,
    required: true,
  },
  is_start: {
    type: Number,
    required: true,
  },
  game_id: {
    type: Number,
    required: true,
  },
  category_id: {
    type: Number,
    required: true,
  },
})

// PARTICIPANTS

var lightboxShow = ref(false);
var lightboxIndex = ref(0);


const lightboxPhotos = computed(() => {
  var items = [];
  for(var i of props.list.items_photo){
    let photo = {};
    if(i.is_video){
      photo.type = 'iframe';
      photo.iframe = '/video/' + i.video_id;
    }          
    photo.base = i.size_big;
    photo.zoom = i.file ? i.file : i.size_big;
    photo.caption = i.comment;
    items.push(photo);        
  }
  return items;
})



const openGallery = function(key){
  lightboxShow.value = true;
  lightboxIndex.value = key;
}

// WINNERS

var lightboxWinnersShow = ref(false);
var lightboxWinnersIndex = ref(0);

const lightboxWinnersPhotos = computed(() => {
  var items = [];
  for(var i of props.list.items_photo_winners){
    let photo = {};
    if(i.is_video){
      photo.type = 'iframe';
      photo.iframe = '/video/' + i.video_id;
    }          
    photo.base = i.size_big;
    photo.zoom = i.file ? i.file : i.size_big;
    photo.caption = i.comment;
    items.push(photo);        
  }
  return items;
})



const openWinnersGallery = function(key){
  lightboxWinnersShow.value = true;
  lightboxWinnersIndex.value = key;
}

</script>

<style scoped>
 
.contest_list{
   width: 100%;
}
.contest_photo{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

</style>
