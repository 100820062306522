<template>

  <UiModal
    v-if="active"
    :width="'600px'"
    :is-auto-height="true"
    :is-mobile-resize="true"
    :is-scroll-content="true"
    @close="closeModal"
    >

      <GiveawayJoinAddressForm 
        v-if="active_form_address" 
        :data="data"
        @close="closeModal"
        @saved="startJoin"
        />
 

      <GiveawayJoinPickDiary
        v-else-if="active_diary_contents && category.type_content == 1"
        :data="data"
        :category="category"
        @pick="join"
        />
 

      <div v-else-if="active_grower_contents && category.type_content == 2">
        Joining...
      </div>



      <GiveawayJoinPickPhotoVideoDiary
        v-else-if="active_photo_diary_contents && category.type_content == 3"
        :data="data"
        :category="category"
        @pick="join"
        />


      <GiveawayJoinPickPhotoVideoDiary
        v-else-if="active_video_diary_contents && category.type_content == 4"
        :data="data"
        :category="category"
        @pick="join"
        />


      <GiveawayJoinUploadPhotoVideo
        v-else-if="active_photo_video_contents && category.type_content == 5"
        :data="data"
        :category="category"
        @pick="join"
        />

      <GiveawayJoinUploadPhotoVideo
        v-else-if="active_photo_video_contents && category.type_content == 6"
        :data="data"
        :category="category"
        @pick="join"
        />

   
  </UiModal>



</template>

<script setup>

const { $api, $popup } = useNuxtApp();
const { t } = useI18n();

const emits = defineEmits(['close']);

const props = defineProps({
  data: {
    type: Object
  },
  category: {
    type: Object
  },
  address: {
    type: Object
  },
  active: {
    type: Boolean
  },
  active_address: {
    type: Boolean
  }
});




const active_diary_contents = ref(false);
const active_photo_diary_contents = ref(false);
const active_video_diary_contents = ref(false);
const active_photo_video_contents = ref(false);
const active_grower_contents = ref(false);
const contents = ref({});
const active_form_address = ref(true);


const startJoin = () => {
  if(!useAuth().isAuth()){
    $popup.error(t('giveaways_view_please_login'));
    return false;
  }

  active_form_address.value = false
  active_diary_contents.value = true;

  if(props.category.type_content == 1){
    active_diary_contents.value = true;
  }else if(props.category.type_content == 2){
    join(useAuth().getId());
    active_grower_contents.value = true;
  }else if(props.category.type_content == 3){
    active_photo_diary_contents.value = true;
    // choosePhotoDiary();
  }else if(props.category.type_content == '4'){
    active_video_diary_contents.value = true;
  }else if(props.category.type_content == '5' || props.category.type_content == '6'){
    active_photo_video_contents.value = true;
  }
}

// const chooseDiary = () => {
//   $api.getGiveawayUserChooseDiary(props.data.id,props.category.id)
//   .then((res) => {
//     active_diary_contents.value = true;
//     contents.value = res;
//   })
//   .catch((error) => {
//     console.log('error');
//   });
// }
 

const join = (content_id) => {
  $api.postGiveawayJoin(props.data.id,props.category.id,content_id)
  .then((res) => {
    $popup.success('Saved');
    active_diary_contents.value = false;
    active_photo_diary_contents.value = false;
    active_video_diary_contents.value = false;
    active_photo_video_contents.value = false;
    closeModal();
  })
  .catch((error) => {
    // console.log(error);
    $popup.error(error);
  })
  
  closeModal();
}

const closeModal = () => {
  active_diary_contents.value = false;
  active_photo_diary_contents.value = false;
  active_video_diary_contents.value = false;
  active_photo_video_contents.value = false;
  active_grower_contents.value = false;
  active_form_address.value = true;

  emits('close');
}
 

 
</script>

<style scoped>


.game_content {
  background-color: white;
  overflow-y: auto;
  padding: 0px 30px;  
  height: calc(100% - 110px); 
  max-height: 400px; 
}

.btn_red{
  background-color: #DB2828;
}

.btn.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.add_content{
    width: 100%;
    height: calc(100% - 51px);
    top: 0;
    left: 0;
    display: none;
    position: fixed;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.41);
}
.add_content_box{
    text-align: center;
}
.add_content_box .item{
        display: inline-block;
    /* margin: 10px; */
    padding: 8px 8px 0px 8px;
    text-align: center;
    vertical-align: top;
}
.add_content_box .item img{
    width: 4rem!important;
    height: 4rem!important;
    margin-bottom: 10px;
}
.add_content_box .item .icon{
    font-size: 3rem;
    /* height: 2rem; */
    /* vertical-align: top; */
    margin-bottom: 23px;
    display: block;
    width: 100%;
}
.add_content_box .item span{
         white-space: nowrap;
    overflow: hidden;
    /* background: #fc0; */
    /* padding: 5px; */
    text-overflow: ellipsis;
    width: 100px;
    display: block;
    color: var(--un-text-color);
}
.add_content .add_content_box{
        /* width: 100%; */
    max-width: 320px;
    min-width: 320px;
    margin-left: calc(50% - 160px);
    position: fixed;
    bottom: 85px;
    height: 120px;
    left: 0px;
    /* display: none; */
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    overflow-x: auto;
    text-align: center;
    /* box-sizing: initial; */
}

.add_content.active{
    display: block;
    text-align: center;
}

/* PICK VIDEOS */


.add_content_box{
    display: block;
    text-align: center;
    margin-top: 20px;
}
.add_content_box .photo_item{
  display: flex;
      width: 100%;
      margin-bottom: 10px;
      position: relative;
      flex-direction: row;
      align-items: center;
}
.add_content_box .photo_item .btn_pick{
    width: 70px;
}
.add_content_box .photo_item .photo_info{
  margin-right: 20px;
      width: calc(100% - 180px);
      text-align: left;
}

.add_content_box .photo_item .photo_info .title{
    display: block;
}
.add_content_box .photo_item .photo_info .hint{
    color: gray;
    display: block;
}

.add_content_box .photo_item > .img_gallery{
  display: inline-block;
      width: 70px;
      height: auto;
      overflow-y: hidden;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      margin-right: 20px;
}
.add_content_box > .photo_item > .img_gallery > img{
    width: 100%;
    height: auto;
    border-radius: 3px;
}





.add_content_box .video_item{
        display: inline-block;
        width: 160px;
        margin-left: 5px;
        margin-right: 5px;
        vertical-align: top;
        position: relative;
        margin-bottom: 15px;
}
.add_content_box .video_item .video_info{

}

.add_content_box .video_item .video_info .title{
    display: block;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.add_content_box .video_item .video_info .hint{
    color: gray;
    display: block;
}

.add_content_box .video_item > .img_gallery{
    display: inline-block;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}
.add_content_box > .video_item > .img_gallery > img{
    width: 100%;
    height: auto;
    border-radius: 3px;
}
.add_content_box .video_play {
    width: 56px;
    height: 56px;
    z-index: 1;
    position: absolute;
    background-image: url(/images/play_button.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 62px;
    left: calc(50% - 28px);
    top: calc(50% - 31px);
    cursor: pointer;
    pointer-events: none;
}

.add_content_box .media_duration{
  background: rgb(0, 0, 0);
    /* margin-bottom: 10px; */
    padding: 4px;
    /* max-width: 720px; */
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.53); */
    border-radius: 4px;
    width: 58px;
    text-align: left;
    margin-bottom: 0px;
    position: absolute;
    /* max-height: 100%; */
    overflow: hidden;
    line-height: 16px;
    z-index: 1;
    max-width: 70%;
    color: white;
    font-size: 0.85rem;
    opacity: 0.8;
    text-align: center;
    right: 6px;
    bottom: 13px;
}





@media only screen and (max-width: 990px){
  .add_content .add_content_box{
    bottom: 65px;
  }
}

</style>
