<template>

  <!-- {{ props.data }} -->
  <!-- {{ giveaways_data }} -->

  <h2 class="title">{{ $t('giveaways_view_address_title') }}</h2>

  <div class="address_content" v-if="!data_profile?.has_address || !is_use_exists">
    <div>
      {{ $t('giveaways_view_address_subtitle1') }}
      {{ $t('giveaways_view_address_subtitle2') }}
    </div>

    <div class="">
      <input type="text" v-model="user_fullname" :placeholder="$t('giveaways_view_address_full_name_placeholder')">
    </div>

    <div class="">
      <input type="text" v-model="user_phone" :placeholder="$t('giveaways_view_address_phone_placeholder')">
    </div>

    <div class="">
      <textarea v-model="user_address" :placeholder="$t('giveaways_view_address_address_placeholder')"></textarea>
    </div>

    <div class="">
      <textarea v-model="user_notes" :placeholder="$t('giveaways_view_address_note_placeholder')"></textarea>
    </div>

    <div class="">
      <input id="terms" type="checkbox" v-model="is_agree">
      <label for="terms">{{ $t('giveaways_view_address_agreement') }}. <a class="item" href="/terms" target="_blank">Read here</a></label>
    </div>

    <br>

    <div class="acts"> 

      <UiButton
        @click="closeModal"
        >
        {{ $t('universal_button_refuse') }}
      </UiButton>
     
      <UiButton
        :type="'primary'"
        :loading="is_saving"
        :disabled="!is_agree"
        @click="saveAddress"
        >
        {{ $t('universal_button_continue') }}
      </UiButton>

    </div>

  </div>

  <div v-else>
    
    <div class="">
      <input id="exists" type="checkbox"  v-model="is_use_exists">
      <label for="exists">Use existing address (You can check address in your <NuxtLink :to="'/grower/' + useAuth().user.value.name + '/settings/cookies'">profile</NuxtLink>)</label>
    </div>
    
    <div class="">
      <input id="terms" type="checkbox"  v-model="is_agree">
      <label for="terms">{{ $t('giveaways_view_address_agreement') }}. <a class="item" href="/terms" target="_blank">Read here</a></label>
    </div>

    <br>

    <div class="acts"> 

      <UiButton
        @click="closeModal"
        >
        {{ $t('universal_button_refuse') }}
      </UiButton>

      <UiButton
        :type="'primary'"
        :loading="is_saving"
        :disabled="!is_agree || !is_use_exists"
        @click="continueWithExists"
        >
        {{ $t('universal_button_continue') }}
      </UiButton>

    </div>

  </div>

</template>

<script setup>

const { t } = useI18n();
const { $api, $popup } = useNuxtApp();

const props = defineProps({
  data: {
    type: Object
  },
  category: {
    type: Object
  }
})

const emits = defineEmits(['close', 'saved']);

const is_agree = ref(true);
const is_use_exists = ref(true);
const is_saving = ref(false);

const user_address = ref('');
const user_fullname = ref('');
const user_phone = ref('');
const user_notes = ref('');
const user_existed = ref(false);

const closeModal = () => {
  emits('close');
}

const continueWithExists = () => {

  emits('saved');
}

const saveAddress = () => {

  if(!useAuth().isAuth()){
    $popup.error(t('giveaways_view_please_login'));
    return false;
  }

  if(!user_address.value || user_address.value.trim().length < 0){
    $popup.error(t('giveaways_view_address_error'));
    return false;
  }

  if(!user_fullname.value || user_fullname.value.trim().length < 0){
    $popup.error(t('giveaways_view_full_name_error'));
    return false;
  }

  if(!user_phone.value || user_phone.value.trim().length < 0){
    $popup.error(t('giveaways_view_phone_error'));
    return false;
  }
 
  is_saving.value = true;

  $api.postProfilePersonal(useAuth().getId(), {
    address: user_address.value,
    fullname: user_fullname.value,
    phone: user_phone.value,
    notes: user_notes.value
  })
  .then((res) => {
    $popup.success('Saved');
    // closeModal();
    emits('saved');
    is_saving.value = false;
  })
  .catch((error) => {
    is_saving.value = false;
    console.log('error save address');
    console.log(error);
  })

}




const loadData = async function() {   
  const response = await $api.getGrowerSettings(useAuth().getId(), 'cookies')  
  return response;  
}


const { pending: is_loading,  data: data_profile } = await useLazyAsyncData('data_profile', async () => await loadData())



 


// const loadData = async function() {   

//   return await $api.getGiveawayUserAddress(props.data.id)  
// }


// const { pending: is_loading,  data: giveaways_data } = await useLazyAsyncData('giveaways_data', loadData)

 

</script>

<style scoped>


.title{
  text-align: center;
  margin-bottom: 1rem;
}

.address_content {
  width: 100%;
  padding: 0 1rem;

  gap: 1rem;
  display: flex;
  flex-direction: column;
}
.acts{
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
