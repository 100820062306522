<template>

    <div class="empty">
      {{ $t('giveaways_view_no_participants') }}
    </div>

</template>

<script>

 
</script>

<style scoped>
 
.empty{
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #999;
}

</style>
